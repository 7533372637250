import React, { Component } from 'react';
import RouteTab from '../../utilities/RouteTab';
import ApiActions from '../../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable, { MTablePagination, MTableHeader, MTableToolbar } from "material-table";
import Dropdown from 'react-dropdown';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRepeat } from '@fortawesome/free-solid-svg-icons'
import FrappButton from '../../utilities/FrappButton';
import FrappModal from "../../utilities/FrappModal";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ProjectTitle from '../../common/ProjectTitle'
import jwt_decode from "jwt-decode";
import { userRoleReason, userRoleAction } from './HiringFunctions'
import Popup from '../../../uicomponents/popup'
import FutworkButton from '../../../uicomponents/button'
import LeadsManagement from '../../../pages/hiring/leadsManagement'
import {TransferAsPerCaller, TransferById} from '../../../pages/hiring/leadsTransfer'
import CustomTab from '../../utilities/CustomTab';
import Table from '../../../uicomponents/table/Table';
import CustomTooltip from '../../utilities/ToolTip';
import handleErrorMessage from '../../../helper/handleErrorMessage';
import constants from '../../../util/constants';

function selectableFilter(row) {
    //return isAmerican(row.birthCountry);
    //return isBritish(row.birthCountry);
    //return isMillenial(row.birthYear);
    return true;
}
class JobApplicationsActive extends Component {
    constructor(props) {
        super(props);
        this.selectedTableRef = React.createRef();
        this.state = {
            skip: 0,
            activeCallers: [],
            selectedCallers: "",
            phoneNumbers: [],
            unprocessedNumbers: [],
            outcomeInsights: [],
            isTableLoading: true,
            leadsManagement: {
                isOpen: false,
                type: '',
                callerData: ''
            },
            leadsTransfer: {
                isOpen: false,
                callerData: '',
                transfer_type: ''
            },
            activeTab: {
                label: this.tabsForStage[0].label,
                value: this.tabsForStage[0].value
            },
            rowSelected: '',
            currentPage: 1,
            limit: 10,
            isActionPopup: false,
            actionType: '',
            isActionTypeNotSelected: false,
            isMobileNumInvalid: {
                msg: '',
                show: false
            },
            projectType: this.checkProjectType()
        }
        this.loggedInUser = jwt_decode(localStorage.authToken)
    }

    checkProjectType(){
        const {hasAllocatedDialing} = this.props && this.props.location && this.props.location.state && 
        this.props.location.state.projectdata || {}
        return hasAllocatedDialing ? 'alp_callers' : 'iqc_callers'
    }
    componentDidMount() {
        // this.setState({projectData: this.props.location.state.projectdata})

        let { skip } = this.state;
        if (this.props.match.params.id) {
            // this.setState({isTableLoading: true})
            ApiActions.getTeleProject(this.props.match.params.id).then(resp => {
                this.setState({ formData: resp })
            })
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getSelectedCallersList(this.props.match.params.id, 'preiqc', skip, this.state.limit).then(resp => {
                this.setState({
                    activeCallers: resp && resp.data,
                    selectedCallers: resp && resp.total ? resp.total : 0,
                    isTableLoading: false,
                }, () => this.addCheckedField())
            }).catch(err => {
                this.setState({isTableLoading: false})
            })

        }
    }

    tabsForStage = [
        {
            label: 'IQC Callers',
            value: 'iqc_callers'
        },
        {
            label: 'ALP Callers',
            value: 'alp_callers'
        }
    ]

    addCheckedField = () => {
        let { activeCallers } = this.state;
        let dummyObj = cloneDeep(activeCallers)
        dummyObj && dummyObj.map(function (datum) {
            datum.checked = false;
            return datum;
        });
        this.setState({ activeCallers: dummyObj })
    }

    fetchCallersBasedOnStage = (id, activeTab) => {
        let { skip } = this.state;
        this.setState({ isTableLoading: true })

        if (activeTab.value == 'iqc_callers') {
            if (this.props.match.params.id) {
                ApiActions.getTeleProject(this.props.match.params.id).then(resp => {
                    this.setState({ formData: resp })
                })
                const skip = (this.state.currentPage - 1) * this.state.limit
                ApiActions.getSelectedCallersList(this.props.match.params.id, 'preiqc', skip, this.state.limit).then(resp => {
                    this.setState({
                        activeCallers: resp && resp.data,
                        selectedCallers: resp && resp.total ? resp.total : 0,
                        isTableLoading: false,
                    })
                }).catch(err => {
                    this.setState({isTableLoading: false})
                })

            }
        } else {
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getSelectedCallersList(id, 'postiqc', skip, this.state.limit).then(resp => {
                this.setState({
                    activeCallers: resp && resp.data,
                    selectedCallers: resp && resp.total ? resp.total : 0,
                    isTableLoading: false,
                })
            }).catch(err => {
                toast(err.message)
                console.error(err.message)
                this.setState({isTableLoading: false})
            })
        }
    }

    render() {
        const { title } = this.props.location.state.projectdata

        let tabs = [
            {
                "name": "Applied Callers",
                "route": `/hiring/applied-callers/${this.props.match.params.id}`,
                "state": this.props.location.state
            },
            {
                "name": "Hiring",
                "route": `/hiring/${this.props.match.params.id}`,
                "state": this.props.location.state
            },
            // {
            //     "name": "Selected Callers",
            //     "route": `/selected-callers/${this.props.match.params.id}`
            // },
            {
                "name": "For Training",
                "route": `/hiring/for-training-callers/${this.props.match.params.id}`,
                "state": this.props.location.state
            }

        ]

        let selectedCallerTab = [
            {
                "name": "Selected Callers",
                "route": `/hiring/selected-callers/${this.props.match.params.id}`,
                "state": this.props.location.state
            }
        ]

        const goBack = () => {
            this.props.history.push("/hiring")
        }


        const setActiveTabFn = (data) => {
            this.setState({ activeTab: { label: data.label, value: data.value } })
            this.setState({ selectAll: false })
            this.setState({ currentPage: 1, rowSelected: ''}, () => this.fetchCallersBasedOnStage(this.props.match.params.id, data))
        }

        return (
            <div className="job-applications">
                <ProjectTitle title={title} goBack={goBack} />
                <div className="bulk-status-row">
                    <div className="section-1">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <RouteTab tabType='multi' tabArray={tabs} activeTab={''} />
                            <RouteTab tabType='single' tabArray={selectedCallerTab} activeTab={selectedCallerTab[0]} />
                        </div>
                        {/* <RouteTab tabArray={tabs} activeTab={tabs[1]} /> */}
                    </div>
                    <div className="section-2">
                        <FutworkButton buttonSize='sm' buttonStyle='primary--solid non-rounded'
                        onClick={() => {
                            const mobileNumbersList = this.state.rowSelected && this.state.rowSelected.map(row => row.mobile)
                            if(mobileNumbersList){
                                this.setState({
                                    phoneNumbers: mobileNumbersList.join('\n')
                                })
                            }
                            this.setState({isActionPopup: true})
                        }}
                        >ACTIONS</FutworkButton>
                    </div>
                </div>
                {
                    this.state.formData && this.state.formData.hasAllocatedDialing ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '1%' }}>
                            {/* <CustomTab tabs={this.tabsForStage} setActiveTabFn={setActiveTabFn} /> */}
                            <div></div>
                        
                        {this.state.projectType == 'alp_callers' && <div  className='navy-dropdown'>
                        <DropdownButton
                            bsSize='medium'
                            title='Lead Action'
                            pullRight={true}
                        >
                        <MenuItem eventKey="1" onSelect={() => this.setState({ leadsManagement: { isOpen: true, type: 'ALL' } })}>Assign Leads</MenuItem>
                        <MenuItem eventKey="2" onSelect={() => {this.setState({ leadsTransfer: { isOpen: true, transfer_type: 'by_lead_id' } })}}>Transfer Leads</MenuItem>
                        </DropdownButton>
                        </div>}
                        </div>
                        : null
                }
                {this.renderTable(this.state.activeTab.value)}
                {/* {this.renderNewActiveCallersTable(this.state.activeTab.value)} */}
                {this.renderBulkStatusPopup()}
                {this.renderDirectAssignPopup()}
                {this.renderDirectClearIqcPopup()}
                {this.renderOutcomeInsightsModal()}
                {this.state.isActionPopup && this.renderActionsPopup()}
                {this.state.leadsManagement.isOpen && this.renderLeadsManagement()}
                {this.state.leadsTransfer.isOpen && this.renderLeadsTransfer()}
                <ToastContainer draggable={true} />
            </div>
        )
    }
    renderBulkStatusPopup = () => {
        let activeCallers = this.state.activeCallers;
        let options = [
            { label: "On Hold", value: "onhold" },
            { label: "Selected", value: "inprogress" },
            { label: "Completed ", value: "completed" },
            { label: "Quit", value: "quit" },
            { label: "Training", value: "training" }
        ]
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.bulkModal = c)}
                closeModal={() => this.bulkModal.toggle(false)}
            >
                <div className="bulk-status-modal">
                    <div className="heading">Bulk status update</div>
                    <div className="dropdown-bulk">
                        <Dropdown options={options}
                            onChange={(val) => this.selectBulk(val)}
                            value={this.state.bulkStatus}
                            placeholder="Select an option" />
                    </div>
                    <div className="bulk-submit-button">
                        <FrappButton className="submit" id="bulk-status-btn"
                            handler={() => this.sendBulkStatusUpdate()}>
                            Submit
                        </FrappButton>
                    </div>

                </div>
            </FrappModal >
        )

    }
    sendBulkStatusUpdate = async () => {
        let { activeCallers, bulkStatus, formData } = this.state;
        let arrId = [];
        let filtered = this.state.rowSelected
        // activeCallers.filter(caller => {
        //     return caller.checked === true
        // })
        filtered.forEach(data => {
            arrId.push(data.id)
        })
        let result = []
        for (const user of filtered) {
            let dataToSend = {
                "teleproject": formData.id,
                "user": user.id,
                "status": bulkStatus,
                "reason": `${bulkStatus} by ${userRoleReason(this.loggedInUser.auth)}`,
                "actionBy": userRoleAction(this.loggedInUser.auth)
            }
            try {
                const updateResult = await ApiActions.sendBulkStatusUpdate(dataToSend)
                if (updateResult.status == "success" || 200) {
                    result.push(`${filtered.mobile} -- ${updateResult.data.msg}`)
                }
            } catch (err) {
                result.push(`${filtered.mobile} -- ${err.response && err.response.data ? err.response.data.msg : 'error'}`)
            }
        }
        this.bulkModal.toggle(false);
        let list = <div>
            {
                result.map(item => <p>{item}</p>)
            }
        </div>
        if (result) {
            toast(list)
            const updatedCallerList = activeCallers.filter(caller =>
                !arrId.includes(caller.id)
            )
            this.setState({ activeCallers: updatedCallerList })
        }
    }
    selectBulk = (val) => {
        this.setState({ bulkStatus: val.value })
    }
    handleBulkClick = () => {
        let { activeCallers } = this.state;
        this.bulkModal.toggle(true);

    }
    fetchData = () => {
        let { activeCallers } = this.state;
        const alpStage = this.state.activeTab.value == 'iqc_callers' ? 'preiqc' : 'postiqc'
        let newData;
        const skip = (this.state.currentPage - 1) * this.state.limit
        ApiActions.getSelectedCallersList(this.props.match.params.id, alpStage, skip, this.state.limit).then(resp => {
            newData = activeCallers.concat(resp && resp.data)
            this.setState({ skip, activeCallers: newData })

        }).catch(err => {
            this.setState({isTableLoading: false})
        })
    }
    _onSelect = (data, val) => {
        let formData = this.state.formData;
        ApiActions.changeUserStatus(
            {
                "teleproject": formData.id,
                "user": data.id,
                "status": val.value,
                "reason": `${val.value} by ${userRoleReason(this.loggedInUser.auth)}`,
                "actionBy": userRoleAction(this.loggedInUser.auth)
            }
        ).then(resp => {
            if (resp.data.status == ("success" || 200)) {
                toast(resp.data.msg)
            }
        }).catch(err => {
            toast(err.response && err.response.data ? err.response.data.msg : 'Error')
        });
    }

    selectRowData = (rowData) => {
        let { activeCallers } = this.state;
        let selected = rowData.checked;
        rowData.checked = !selected;
        this.setState({ activeCallers })
    }
    selectAllRows = () => {
        let { activeCallers, selectAll } = this.state;
        selectAll = !selectAll
        this.setState({ selectAll })
        if (selectAll) {
            let newObj = cloneDeep(activeCallers)
            newObj.map(function (datum) {
                datum.checked = true;
                return datum;
            });
            this.setState({ activeCallers: newObj })
        }
        else {
            let newObj = cloneDeep(activeCallers)
            newObj.map(function (datum) {
                datum.checked = false;
                return datum;
            });
            this.setState({ activeCallers: newObj })
        }

    }

    tooltip = (info) => {
        return (<Tooltip id="tooltip">
            {info}
        </Tooltip>)
    };

    downloadCallerReport = (data) => {
        const { formData } = this.state
        const userId = data.id
        const projectId = formData.id
        ApiActions.getCallerDataToDownload(projectId, userId)
            .then(resp => {
                if (resp.length === 0) {
                    alert("No data to download")
                } else {
                    let tempResponse = []
                    resp.forEach((item) => {
                        tempResponse.push({
                            "mobile": item.teletask ? `XXXXX${item.teletask.mobile.toString().substring(5)}` : "",
                            "recordingURL": item.exotel.RecordingUrl ? item.exotel.RecordingUrl : "",
                            "callDuration(min)": item.exotel.Details ? Math.ceil(parseInt(item.exotel.Details.ConversationDuration) / 60) : "",
                            "feedbacks": item.qc ? item.qc.feedbacks ? item.qc.feedbacks.toString() : "" : "",
                            // "responses": item.outcome ? item.outcome.description : ""
                        })
                    })

                    const options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        showTitle: true,
                        title: `${data.firstname} ${data.lastname} ----  ${formData.title}`,
                        useTextFile: false,
                        useBom: true,
                        useKeysAsHeaders: true,
                        filename: `${data.firstname}__${formData.title}`
                    };
                    const csvExporter = new ExportToCsv(options);
                    tempResponse.length !== 0 ? csvExporter.generateCsv(tempResponse) : csvExporter.generateCsv([{ "No Data": "No Data Available" }]);
                }
            })
    }

    renderTable = (activeTab) => {
        switch (this.state.projectType) {
            case 'iqc_callers':
                return this.renderNewActiveCallersTable()
            case 'alp_callers':
                return this.renderAlpCallersTable()
            default:
                break;
        }
    }

    goToFollowUpList = (data) => {
        this.props.history.push({
            pathname: `/hiring/follow-up-calls/${this.props.match.params.id}`,
            state: {
                callerDetails: data,
                projectdata: this.props.location.state.projectdata
            }
        })
    }

    renderNewActiveCallersTable = () => {
        let { activeCallers } = this.state;
        const { statuses } = constants
        let options = [
            { label: "On Hold", value: "onhold" },
            { label: "Selected", value: "inprogress" },
            { label: "Completed ", value: "completed" },
            { label: "Quit", value: "quit" },
            { label: "Training", value: "training" }
        ]

        const getIQCStatus = (iqc) => {
            if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqc.status == 'pass') {
                return 'Cleared'
            } else if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqcHolds && iqc.iqcHolds.length > 0 && iqc.iqcHolds[0].attempts > 1) {
                return 'Additional IQC'
            }
            else if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqc.status == 'fail') {
                return 'Failed'
            } else {
                return 'Not submitted'
            }
        }

        const getQAStatus = (qc) => {
            if (!qc || typeof qc) {
                return 'Not submitted'
            } else if (qc && qc.status == 'audited') {
                return 'Cleared'
            } else if (qc && qc.status == 'flagged' && qc.sampling && qc.sampling.length > 0) {
                return 'Daily QC'
            } else if (qc && qc.status == 'flagged' && qc.autoHolds && qc.autoHolds.length > 0) {
                return 'Autohold'
            } else if (qc && qc.status == 'flagged' && qc.suspects && qc.suspects.length > 0) {
                return 'Suspect'
            }
        }

        const columns = [
            {
                Header: 'Name',
                accessor: 'firstname',
                Cell: ({row}) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                },
                // width: "5%",
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: 'mobile',
                Cell: ({value}) => (value || 'NA'),
                // maxWidth: 110,
                // minWidth: 100,
                // width: 100,
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({value}) => (value || 'NA'),
                // maxWidth: 160,
                // minWidth: 150,
                // width: 160,
                disableSortBy: true
            },
            {
                Header: 'Date assigned',
                accessor: 'createdAt',
                Cell: ({row}) => {
                    const {original: {createdAt}} = row
                    return <span>{moment(createdAt).format("DD/MM/YYYY")}</span>
                },
                getCellExportValue: (row) => {
                    const {original: {createdAt}} = row
                    return moment(createdAt).format("DD/MM/YYYY")
                }
            },
            {
                Header: 'Total calls attempted',
                accessor: 'callsAttemptedCount',
                Cell: ({value}) => (value || 0),
                // maxWidth: 80,
                // minWidth: 50,
                // width: 60
            },
            {
                Header: `SCB open leads`,
                accessor: 'scbOpenLeadsCount',
                Cell: ({value}) => (value || 0)
            },
            {
                Header: 'Follow ups',
                accessor: 'followupCount',
                Cell: ({ cell }) => {
                    const value = cell.row.original
                    const { followupCount, overdueCount } = value
                    return <div className='followup' onClick={() => followupCount > 0 && this.goToFollowUpList(value)}>
                        <div className={followupCount > 0 && 'followup-count'}>{followupCount}</div> {overdueCount > 0 && <div className='overdue-follow-up'>{overdueCount} Overdue</div>}</div>
                }
            },
            {
                Header: 'IQC Status',
                accessor: 'qc',
                Cell: ({ cell }) => {
                    const value = cell.row.original
                    return getIQCStatus(value.qc)
                },
                sortType: compareIQCStatus,
                // maxWidth: 100,
                // minWidth: 25,
                // width: 100,
                getCellExportValue: (row) => {
                    const {original: {qc}} = row
                    return getIQCStatus(qc)
                }
            },
            {
                Header: 'Status',
                accessor: "status",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <Dropdown options={options}
                            onChange={(val) => this._onSelect(value, val)}
                            value={value.status ? value.status : "Select"}
                            placeholder="Select an option" />
                },
                // maxWidth: 170,
                // minWidth: 150,
                // width: 160,
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {status}} = row
                    return status ? statuses.find(option => 
                        option.value == status
                    )['label'] : 'Status not available'
                },
            },
            {
                Header: 'Actions',
                accessor: "action",
                Cell: (cell) => {
                    const value = cell.row.original
                    return <div className='table-data'>
                        <div className="action-btns">
                            <span>
                                <div className="stats-action">
                                <CustomTooltip placement={'bottom'} description={'Outcome insights'}
                                    component={<img title="Outcome insights" src={require("../../../assets/images/completed.svg")} onClick={() => this.callOutcomeInsightsModal(value)} style={{ width: '36px' }} />}
                                />
                                </div>
                                <div className="stats-report">
                                <CustomTooltip placement={'bottom'} description={'Download Report'}
                                    component={<img title="Download Report" src={require("../../../assets/images/Icons_download_01.svg")}
                                        onClick={() => this.downloadCallerReport(value)} style={{ width: '36px' }} />}
                                />
                                </div>
                            </span>
                        </div>
                    </div>
                },
                // width: "2%"
                disableSortBy: true,
                disableExport: true
            }
        ]

        function compareIQCStatus(rowA, rowB, id, desc) {
            console.log('compare iqc status')
            const statusOrder = {
                "pass": 1,
                "underiqc": 2
            };
        
            // Get the status values
            let a = rowA.values[id] && rowA.values[id].iqc && rowA.values[id].iqc.status.toLowerCase() || "";
            let b = rowB.values[id] && rowB.values[id].iqc && rowB.values[id].iqc.status.toLowerCase() || "";

            console.log('a', a, 'b', b, 'statusOrder[a]', statusOrder[a], 'statusOrder[b]', statusOrder[b])
            console.log('Number.NEGATIVE_INFINITY', Number.NEGATIVE_INFINITY)
            console.log('Number.POSITIVE_INFINITY', Number.POSITIVE_INFINITY)
        
            // Assign default values for invalid statuses or blanks
            a = statusOrder[a] || (desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY);
            b = statusOrder[b] || (desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY);
        
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }

        return (
            <div style={{ maxWidth: "100%" }}>
            <Table
            columns={columns}
            data={activeCallers}
            totalCount={this.state.selectedCallers}
            limit={10}
            type = 'client'
            selectType='multi'
            selectRow = {true}
            updateRowSelect={(rows = []) => this.setState({rowSelected: rows})}
            currentPage={this.state.currentPage}
            setCurrentPage={(page) => this.setState({currentPage: page}, () => 
                this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
            )}
            loading={this.state.isTableLoading}
            // sortChange={sortChange}
            // filterChange={filterChange}
            // searchBy='project title or mobile'
            isGlobalFilter={true}
            manualSortBy = {false}
            title='Selected-callers'
            isExport={true}
            />

            </div>
        )

    }

    renderAlpCallersTable = () => {
        let { activeCallers } = this.state;
        const { statuses } = constants
        let options = [
            { label: "On Hold", value: "onhold" },
            { label: "Selected", value: "inprogress" },
            { label: "Completed ", value: "completed" },
            { label: "Quit", value: "quit" },
            { label: "Training", value: "training" }
        ]

        const getIQCStatus = (iqc) => {
            if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqc.status == 'pass') {
                return 'Cleared'
            } else if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqcHolds && iqc.iqcHolds.length > 0 && iqc.iqcHolds[0].attempts > 1) {
                return 'Additional IQC'
            }
            else if (iqc && Object.keys(iqc).length > 0 && iqc.iqc && iqc.iqc.status == 'fail') {
                return 'Failed'
            } else {
                return 'Not submitted'
            }
        }

        const columns = [
            {
                Header: 'Name',
                accessor: 'firstname',
                Cell: ({row}) => {
                    const {original: {firstname, lastname}} = row
                    return <span>{firstname || 'NA'} {lastname || 'NA'}</span>
                },
                width: "10%",
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: 'mobile',
                Cell: ({value}) => (value || 'NA'),
                maxWidth: 110,
                minWidth: 100,
                width: 100,
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({value}) => (value || 'NA'),
                maxWidth: 160,
                minWidth: 150,
                width: 160,
                disableSortBy: true
            },
            {
                Header: 'Date assigned',
                accessor: 'createdAt',
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <span>{moment(value.createdAt).format("DD/MM/YYYY")}</span>
                },
                width: "9%",
                getCellExportValue: (row) => {
                    const {original: {createdAt}} = row
                    return moment(createdAt).format("DD/MM/YYYY")
                }
            },
            {
                Header: 'Total calls attempted',
                accessor: 'callsAttemptedCount',
                Cell: ({value}) => (value || 0),
                maxWidth: 80,
                width: 60
            },
            {
                Header: `Total leads assigned`,
                accessor: 'totalAssignedLeadsCount',
                Cell: ({value}) => (value || 0),
                width: "9%"
            },
            {
                Header: `Userpool open leads`,
                accessor: 'userpoolOpenLeadsCount',
                Cell: ({value}) => (value || 0),
                width: "9%"
            },
            {
                Header: `SCB open leads`,
                accessor: 'scbOpenLeadsCount',
                Cell: ({value}) => (value || 0),
                width: "9%"
            },
            {
                Header: 'Follow ups',
                accessor: 'followupCount',
                Cell: ({ cell }) => {
                    const value = cell.row.original
                    const { followupCount, overdueCount } = value
                    return <div className='followup' onClick={() => followupCount > 0 && this.goToFollowUpList(value)}>
                        <span className={followupCount > 0 && 'followup-count'}>{followupCount}</span> {overdueCount > 0 && <span className='overdue-follow-up'>{overdueCount} Overdue</span>}</div>
                },
                width: "10%"
            },
            {
                Header: 'IQC Status',
                accessor: 'qc',
                Cell: ({ cell }) => {
                    const value = cell.row.original
                    return getIQCStatus(value.qc)
                },
                sortType: compareIQCStatus,
                getCellExportValue: (row) => {
                    const {original: {qc}} = row
                    return getIQCStatus(qc)
                }
            },
            {
                Header: 'Status',
                accessor: "status",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div>
                        <Dropdown options={options}
                            onChange={(val) => this._onSelect(value, val)}
                            value={value.status ? value.status : "Select"}
                            placeholder="Select an option" />
                    </div>
                },
                getCellExportValue: (row) => {
                    const {original: {status}} = row
                    return status ? statuses.find(option => 
                        option.value == status
                    )['label'] : 'Status not available'
                },
                maxWidth: 170,
                minWidth: 150,
                width: 160,
            },
            {
                Header: 'Actions',
                accessor: "action",
                Cell: (cell) => {
                    const value = cell.row.original
                    return <div className='table-data'>
                        <div className="action-btns">
                            <span>
                                <div className="stats-action">
                                <CustomTooltip placement={'bottom'} description={'Outcome insights'}
                                  component={<img title="Outcome insights" src={require("../../../assets/images/completed.svg")} onClick={() => this.callOutcomeInsightsModal(value)} style={{ width: '36px' }} />} />
                                </div>
                                <div className="stats-report">
                                <CustomTooltip placement={'bottom'} description={'Download Report'}
                                    component={<img title="Download Report" src={require("../../../assets/images/Icons_download_01.svg")}
                                        onClick={() => this.downloadCallerReport(value)} style={{ width: '36px' }} />} />
                                </div>
                                <div className={`stats-report ${value.userpoolOpenLeadsCount <= 0 && 'disabled' }`}>
                                <CustomTooltip placement={'bottom'} description={value.userpoolOpenLeadsCount <= 0 ? 'No open leads to transfer' : 'Transfer open leads'}
                                    component={<FontAwesomeIcon icon={faRepeat} 
                                    style={{fontSize: '21px'}} onClick={() => value.userpoolOpenLeadsCount > 0 && this.setState({ leadsTransfer: { isOpen: true, callerData: value } })}/>}
                                />
                                </div>
                            </span>
                        </div>
                    </div>
                },
                width: "2%",
                disableSortBy: true,
                disableExport: true
            }
        ]

        function compareIQCStatus(rowA, rowB, id, desc) {
            console.log('compare iqc status')
            const statusOrder = {
                "pass": 1,
                "underiqc": 2
            };
        
            // Get the status values
            let a = rowA.values[id] && rowA.values[id].iqc && rowA.values[id].iqc.status.toLowerCase() || "";
            let b = rowB.values[id] && rowB.values[id].iqc && rowB.values[id].iqc.status.toLowerCase() || "";

            console.log('a', a, 'b', b, 'statusOrder[a]', statusOrder[a], 'statusOrder[b]', statusOrder[b])
            console.log('Number.NEGATIVE_INFINITY', Number.NEGATIVE_INFINITY)
            console.log('Number.POSITIVE_INFINITY', Number.POSITIVE_INFINITY)
        
            // Assign default values for invalid statuses or blanks
            a = statusOrder[a] || (desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY);
            b = statusOrder[b] || (desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY);
        
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }

        return (
            <div style={{ maxWidth: "100%" }}>
            <Table
            columns={columns}
            data={activeCallers}
            totalCount={this.state.selectedCallers}
            limit={10}
            type = 'client'
            selectType='multi'
            selectRow = {true}
            updateRowSelect={(rows = []) => this.setState({rowSelected: rows})}
            currentPage={this.state.currentPage}
            setCurrentPage={(page) => this.setState({currentPage: page}, () => 
                this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
            )}
            loading={this.state.isTableLoading}
            // sortChange={sortChange}
            // filterChange={filterChange}
            // searchBy='project title or mobile'
            isGlobalFilter={true}
            manualSortBy = {false}
            title='ALP-callers'
            isExport={true}
            // search={search}
            />
            </div>
        )

    }

    handleDirectClearIqcClick = () => {
        this.directClearIqcModal.toggle(true);
    }

    renderDirectClearIqcPopup = () => {
        return (
            <FrappModal
                onCloseButton={true}
                className="direct-assign-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.directClearIqcModal = c)}
                closeModal={() => this.directClearIqcModal.toggle(false)}
                closeButtonClicked={() => this.setState({phoneNumbers: [], unprocessedNumbers: []})}
            >
                <div className="direct-assign-wrapper">
                    <div className="heading">Direct Clear IQC Of Callers</div>
                    <div className="assign-caller-textarea">
                        <FormGroup controlId="formControlsTextarea">
                            {/* <ControlLabel>Textarea</ControlLabel> */}
                            <FormControl
                                componentClass="textarea"
                                placeholder="Enter Callers Phone Number (example: 9000123456, 9001234567)"
                                onChange={(e) => this.setIqcCallerNumbers(e)}
                            />
                        </FormGroup>
                    </div>
                    <div className='info'>
                    <span>NOTE: Maximum limit is 100 mobile numbers</span>
                    <span>Mobile numbers: {this.state.phoneNumbers && this.state.phoneNumbers.length || 0}</span>
                    </div>
                    <div className="assign-submit-button">
                        <FrappButton className="submit" id="bulk-status-btn"
                            handler={() => this.state.phoneNumbers && this.state.phoneNumbers.length > 100 ? toast(`Limit Exceeded: Mobile numbers should not be more than 100. Current count is ${this.state.phoneNumbers.length}`) : this.sendIqcCallers()}
                            disabled={this.state.phoneNumbers && this.state.phoneNumbers.length > 0 ? false:true}
                            ref={(c) => this.submitPhoneNumbers = c}>
                            Submit
                        </FrappButton>
                    </div>
                    {
                        this.state.unprocessedNumbers.length === 0
                            ? null
                            : this.renderUnprocessedNumbers()
                    }
                </div>
            </FrappModal>
        )
    }

    sendIqcCallers = () => {
        if(this.state.isMobileNumInvalid.msg){
            this.setState({isMobileNumInvalid: {
                ...this.state.isMobileNumInvalid,
                show: true
            }})
            return false
        } 
        if(!this.state.actionType){
            this.setState({isActionTypeNotSelected : true})
            return false
        }
        this.submitPhoneNumbers.startLoading();
        this.setState({unprocessedNumbers: []})
        ApiActions.directClearIqc({
            teleprojectId: this.props.match.params.id,
            mobile: this.formatPhoneNumberForPayload(this.state.phoneNumbers),
        })
        .then((resp) => {
            if (resp.data.unprocessed.length !== 0) {
                this.setState({
                    unprocessedNumbers: resp.data.unprocessed
                })
            }
            toast(
                `Processed count: ${resp.data.processed.length}  Unprocesed count: ${resp.data.unprocessed.length
                }`
            );
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getSelectedCallersList(this.props.match.params.id, 'preiqc', skip, this.state.limit).then(resp => {
                this.setState({
                    activeCallers: resp && resp.data,
                    selectedCallers: resp && resp.total ? resp.total : 0,
                    isTableLoading: false,
                }, () => this.addCheckedField())
            }).catch(err => {
                this.setState({isTableLoading: false})
            })
        })
        .catch((err) => {
            toast(handleErrorMessage(err.response.data));
            console.error(err.response)
        })
        .finally(() => this.submitPhoneNumbers.stopLoading())
    }

    changeStatusOfCallerInBulk = async () => {
        if(this.state.isMobileNumInvalid.msg){
            this.setState({isMobileNumInvalid: {
                ...this.state.isMobileNumInvalid,
                show: true
            }})
            return false
        } 
        if(!this.state.actionType){
            this.setState({isActionTypeNotSelected : true})
            return false
        }
        this.submitPhoneNumbers.startLoading();
        this.setState({unprocessedNumbers: []})
        const {phoneNumbers, actionType} = this.state
        const payload  = {
            mobile: this.formatPhoneNumberForPayload(this.state.phoneNumbers),
            teleprojectId: this.props.match.params.id,
            status: actionType
        }

        try {
            let result = await ApiActions.bulkStatusChange(payload)
            if (result.data.unprocessed.length !== 0) {
                this.setState({
                    unprocessedNumbers: result.data.unprocessed
                })
            }
            toast(
                `Processed count: ${result.data.processed.length}  Unprocesed count: ${result.data.unprocessed.length
                }`
            );
        } catch (error) {
            toast(handleErrorMessage(error.response.data));
            console.error(error.response)
        }finally{
            this.submitPhoneNumbers.stopLoading()
        }
    }

    setIqcCallerNumbers = (e) => {
        this.setState({
            phoneNumbers: e.target.value && e.target.value.length > 0 ? e.target.value.split(/[ ,]+/) : e.target.value
        })
    };

    setBulkUpdateCallerNumbers = (e) => {
        this.setState({
            phoneNumbers: e.target.value && e.target.value.length > 0 ? e.target.value.split(/[ ,]+/) : e.target.value
        })
    }

    handleDirectAssignClick = () => {
        this.directAssignModal.toggle(true);
    }

    renderDirectAssignPopup = () => {
        return (
            <FrappModal
                onCloseButton={true}
                className="direct-assign-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.directAssignModal = c)}
                closeModal={() => this.directAssignModal.toggle(false)}
                closeButtonClicked={() => this.setState({phoneNumbers: [], unprocessedNumbers: []})}
            >
                <div className="direct-assign-wrapper">
                    <div className="heading">Direct Assign Callers</div>
                    <div className="assign-caller-textarea">
                        <FormGroup controlId="formControlsTextarea">
                            {/* <ControlLabel>Textarea</ControlLabel> */}
                            <FormControl
                                componentClass="textarea"
                                placeholder="Enter Callers Phone Number (example: 9000123456, 9001234567)"
                                onChange={(e) => this.setAssignCallerNumbers(e)}
                            />
                        </FormGroup>
                    </div>
                    <div className="assign-submit-button">
                        <FrappButton className="submit" id="bulk-status-btn"
                            handler={() => this.sendAssignCallers()}
                            disabled={this.state.phoneNumbers && this.state.phoneNumbers.length > 0 ? false:true}
                            ref={(c) => this.submitPhoneNumbers = c}>
                            Submit
                        </FrappButton>
                    </div>
                    {
                        this.state.unprocessedNumbers.length === 0
                            ? null
                            : this.renderUnprocessedNumbers()
                    }
                </div>
            </FrappModal>
        )
    }

    formatPhoneNumberForPayload = (numberList) => {
        if(numberList && numberList.length > 0){
        if(typeof numberList == 'object'){ //this condition will be met if the numbers are manually entered or if the numbers are pasted from a source
            let  mobileNums = numberList && numberList[0].split('\n')
            // trim empty spaces
            let removeEmptyElements = mobileNums && mobileNums.length > 0 ? mobileNums.filter(Boolean) : mobileNums
            return removeEmptyElements
        }else{ // this condiiton is met when the numbers are auto populated from the agents are checked in from the selected callers table
            let firstStep = numberList && numberList.split()
            let secondStep = firstStep && firstStep[0].split('\n')
            // trim empty spaces
            let removeEmptyElements = secondStep && secondStep.length > 0 ?  secondStep.filter(Boolean) : secondStep
            return removeEmptyElements
        } 
        }else{
            return []
        }
    }
    sendAssignCallers = () => {
        if(this.state.isMobileNumInvalid.msg){
            this.setState({isMobileNumInvalid: {
                ...this.state.isMobileNumInvalid,
                show: true
            }})
            return false
        } 
        if(!this.state.actionType){
            this.setState({isActionTypeNotSelected : true})
            return false
        }
        this.submitPhoneNumbers.startLoading();
        this.setState({unprocessedNumbers: []})
        ApiActions.assignUsers({
            teleproject: this.props.match.params.id,
            mobile: this.formatPhoneNumberForPayload(this.state.phoneNumbers),
        })
            .then((resp) => {
                if (resp.data.unprocessed.length !== 0) {
                    this.setState({
                        unprocessedNumbers: resp.data.unprocessed
                    })
                }
                toast(
                    `Processed count: ${resp.data.processed}  Unprocesed count: ${resp.data.unprocessed.length
                    }`
                );
                this.submitPhoneNumbers.stopLoading();
            })
            .catch((err) => {
                toast(handleErrorMessage(err.response.data));
                console.error(err.response)
            });
    }

    validateValue(elem) {
       let errorMsg = ''
        const isNumeric = (str) => {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str))
        }
        const value = elem.target.value.trim();
        // const mobileNumArray = value.split(/[ ,]+/).map(elem => elem)
        const mobileNumArray = value.split(/[ /\n]+/).map(elem => elem)
        const invalidMobileNumbers = mobileNumArray.map(element => 
        (isNumeric(element) == false) || (element.toString().length > 10))
        .filter(el => el == true)
        if(mobileNumArray[mobileNumArray.length - 1] == '') errorMsg = 'Please remove the comma added after the last mobile number added'
        else if(invalidMobileNumbers && invalidMobileNumbers.length > 0) errorMsg = `${invalidMobileNumbers.length} ${invalidMobileNumbers. length > 1  ? `number's are` : 'number is'} invalid`
        this.setState({isMobileNumInvalid: {msg: errorMsg, show: false}})
    }

    setAssignCallerNumbers = (e) => {
        this.setState({
            phoneNumbers: e.target.value && e.target.value.length > 0 ? e.target.value.split(/[ ,]+/) : e.target.value
        })
    };

    renderUnprocessedNumbers = () => {
        let { unprocessedNumbers } = this.state
        return (
            <div style={{ padding: "2% 0%" }}>
                <div>
                    <h4>Unprocessed Number</h4>
                </div>
                <div>
                    {
                        unprocessedNumbers && unprocessedNumbers.join(", ")
                    }
                </div>
            </div>
        )
    }


    callOutcomeInsightsModal = (rowData) => {
        const data = {
            teleproject: this.props.match.params.id,
            user: rowData.id
        }
        ApiActions.getCallOutcomeInsights(data).then(resp => {
            this.setState({ outcomeInsights: resp })
            this.outcomeInsightsModal.toggle(true)
        }).catch(err => {
            console.error(err)
            this.outcomeInsightsModal.toggle(true)
        })
    }


    renderOutcomeInsightsModal = () => {
        const { activeCallers, outcomeInsights } = this.state

        const calculateDeviation = (data) => {
            const { isWinning } = data

            const assignPosNegSign = (callerAvg, sysAvg, deviation) => {
                //assign positive if callerAvg > sysAvg
                if (callerAvg > sysAvg) {
                    return deviation
                }
                //assign negative if callerAvg < sysAvg
                else if (callerAvg < sysAvg) {
                    return deviation
                }
                //deviation is 0 if callerAvg == sysAvg
                else {
                    return deviation
                }
            }

            const acceptableRange = (sysAvg, isWinning) => {
                //use this range if its winning outcome
                const winningRange = {
                    low: 15,
                    high: 15
                }
                //use this range if its non-winning outcome
                const nonWinningRange = {
                    low: 20,
                    high: 20
                }

                //acceptable range calculated if its a winning outcome
                if (isWinning) {
                    return {
                        low: sysAvg - winningRange.low,
                        high: sysAvg + winningRange.high
                    }
                }
                //acceptable range calculated if its a non-winning outcome
                else {
                    return {
                        low: sysAvg - nonWinningRange.low,
                        high: sysAvg + nonWinningRange.high
                    }
                }
            }

            const checkSuspect = (callerAvg, acceptableLimit) => {
                if ((callerAvg >= acceptableLimit.low) && (callerAvg <= acceptableLimit.high)) {
                    return false
                } else {
                    //if the above condition returns false then the caller is a suspect
                    return true
                }
            }

            const assignAlertImage = (isSuspect, deviation) => {
                if (isSuspect) {
                    return <span class='deviation-wrap'>{deviation}%<img src={require('../../../assets/images/Icons-alert.svg')} /></span>
                } else {
                    return <span class='deviation-wrap'>{deviation == 0 ? '-' : `${deviation}%`}<img /></span>
                }
            }

            const callerAvg = Math.round(data.userAvg)
            const systemAvg = Math.round(data.sysAvg)
            let deviation = callerAvg - systemAvg
            //assign the positive or negative sign to deviation
            deviation = assignPosNegSign(callerAvg, systemAvg, deviation)
            //get the acceptable range to check with systemAvg
            const limitRange = acceptableRange(systemAvg, isWinning)
            //check if the caller average is suspect
            const isSuspect = checkSuspect(callerAvg, limitRange)
            return (
                <div className={`call-outcome ${isSuspect ? 'suspect' : 'not-suspect'}`}>{assignAlertImage(isSuspect, deviation)}</div>
            )
        }
        return (
            <FrappModal
                onCloseButton={true}
                className="cropper-modal call-history-modal"
                id="report-modal"
                show={this.state.reportModal}
                ref={(c) => (this.outcomeInsightsModal = c)}
                closeButtonClicked={() => {
                    //   this.setState({outcomeInsights: []})
                    this.outcomeInsightsModal.toggle(false)
                }}
            //   closeModal={() => this.outcomeInsightsModal.toggle(false)}
            >
                <div className="heading">Outcome insights</div>
                <div className="report-modal">
                    <div style={{ width: "100%" }}>
                        <MaterialTable
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No records to display',
                                    // filterRow: {
                                    //     filterTooltip: 'Filter'
                                    // }
                                }
                            }}
                            columns={[
                                {
                                    title: "Outcomes",
                                    field: "outcome",
                                    render: (rowData => {
                                        return <div className='call_outcome_winning_outcome'>{rowData.outcome}{rowData.isWinning == true ? <img src={require("../../../assets/images/Icons_yellow star.svg")} className='call_outcome_winning_outcome_star' /> : null}</div>
                                    }),
                                    width: "25%",
                                },

                                {
                                    title: "System Average",
                                    field: "sysAvg",
                                    width: "25%",
                                    render: rowData =>
                                        <div>
                                            {Math.round(rowData.sysAvg)}%
                                        </div>
                                },
                                {
                                    title: "Caller Average", field: "userAvg",
                                    width: "25%",
                                    render: rowData =>
                                        <div>
                                            {Math.round(rowData.userAvg)}%
                                        </div>,
                                },

                                {
                                    title: "Deviation",
                                    width: "25%",
                                    render: rowData => <span>{calculateDeviation(rowData)}</span>
                                },

                            ]}
                            data={outcomeInsights}
                            actions={[
                                //   {
                                //       icon: (props) =>  <Checkbox className="checkbox"  
                                //           >

                                //           </Checkbox>,
                                //      //icon:'save',
                                //       tooltip: 'Select User',
                                //      onClick: (event, rowData) => console.log(rowData),
                                //       //isFreeAction: true,
                                //       className:"bulk-checkbox"
                                //     }
                                //     ,
                            ]}

                            components={
                                {
                                    //     Action: props => (

                                    //      <Checkbox className="checkbox"  onChange={(event) => props.action.onClick(event, props.data)}
                                    //       >
                                    //           { console.log(props.data)}
                                    //       </Checkbox>
                                    // ),

                                    // Pagination: props => (
                                    //     <div style={{ fontSize: "30px" }}>
                                    //         <MTablePagination rowsPerPage={50} {...props} />
                                    //     </div>
                                    // ),
                                }

                            }
                            // onChangePage={()=>this.fetchData()}
                            tableRef={this.tableRef}
                            isTableLoading={true}
                            options={{
                                // selection:true,
                                sorting: false,
                                filtering: false,
                                grouping: false,
                                toolbar: false,
                                paging: false,
                                exportButton: false,
                                // doubleHorizontalScroll:true,
                                exportAllData: false,
                                columnsButton: true,
                                // pageSize:25,
                                emptyRowsWhenPaging: false,
                                // pageSizeOptions:[25,50,75,100],
                                // rowStyle: {
                                //     fontSize: "16px"
                                // },
                                cellStyle: {
                                    fontSize: '14px',
                                    color: 'black'
                                }, headerStyle: {
                                    backgroundColor: '#E7FAF2',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    boxShadow: 'none'

                                },
                                // searchFieldStyle: {
                                //     boxShadow: 'none',
                                //     webkitboxShadow: 'none',
                                // },
                                //showSelectAllCheckbox:true,
                            }}
                        // title= {`Active Callers \u00A0\u00A0 [ ${this.state.selectedCallers} ]`}

                        />
                    </div>
                </div>
            </FrappModal>)
    }

    
    renderActionsPopup = () => {
        const { isActionPopup } = this.state
        const { customRole: {ADMIN, PROJECT_MANAGER, COACH, TEAM_LEAD}} = constants
        const options = [
            { label: "Direct Assign", value: "direct_assign", 
            permission: [ADMIN, PROJECT_MANAGER, COACH ]
            },
            { label: "Direct Clear IQC", value: "direct_clear_iqc", 
            permission: [ADMIN] },
            { label: "On Hold", value: "onhold", permission: ['ALL']},
            { label: "Selected", value: "inprogress", permission: ['ALL']},
            { label: "Completed", value: "completed", permission: ['ALL']},
            { label: "Quit", value: "quit", permission: ['ALL'] },
            { label: "Training", value: "training", permission: ['ALL'] }
        ]

        const optionBasedOnRole = options.filter((opt) => {
            if((opt.permission.includes(this.loggedInUser.auth)) || (opt.permission.includes('ALL'))) return true
         })
 
        return (
            <Popup
                show={isActionPopup}
                closePopup={() => {
                    // this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                    // this.addCheckedField()
                    // this.setState({ leadsManagement: { isOpen: false, type: '' } })
                    this.setState({ isActionPopup: false, actionType: '', isActionTypeNotSelected: false })
                    this.setState({ phoneNumbers: [], unprocessedNumbers: [], isMobileNumInvalid: {msg: '', show: false} })
                }}
                size='sm'
                heading={`Action`}
            >
                <div className="direct-assign-wrapper">
                <Dropdown options={optionBasedOnRole}
                onChange={(val) => {
                    this.setState({actionType: val.value})
                    this.setState({ unprocessedNumbers: [], isActionTypeNotSelected: false })
                }}
                value={this.state.actionType.value}
                placeholder="Select an action" />
                <div className="assign-caller-textarea">
                <FormGroup controlId="formControlsTextarea">
                <FormControl
                componentClass="textarea"
                value={this.state.phoneNumbers}
                // placeholder="Enter Callers Phone Number (example: 9000123456, 9001234567)"
                placeholder={`Enter Callers Phone Number\n9000123456\n9001234567`}
                onChange={(e) => {
                    this.validateValue(e)
                    switch (this.state.actionType) {
                        case options[0].value:
                            return this.setAssignCallerNumbers(e)
                        case options[1].value:
                            return this.setIqcCallerNumbers(e)
                        default:
                            return this.setBulkUpdateCallerNumbers(e)
                            break;
                    }
                }}
                />
                </FormGroup>
                </div>
                <div className='info'>
                <span>NOTE: Maximum limit is 100 mobile numbers</span>
                <span>Mobile numbers: {this.state.phoneNumbers && this.formatPhoneNumberForPayload(this.state.phoneNumbers).length || 0}</span>
                </div>
                <div className="assign-submit-button">
                <FrappButton className="submit" id="bulk-status-btn"
                handler={() => {
                    switch (this.state.actionType) {
                        case options[0].value:
                            return this.sendAssignCallers()
                        case options[1].value:
                            return this.state.phoneNumbers && this.state.phoneNumbers.length > 100 ?
                            toast(`Limit Exceeded: Mobile numbers should not be more than 100. Current count is ${this.formatPhoneNumberForPayload(this.state.phoneNumbers).length}`) : 
                            this.sendIqcCallers()
                        default:
                            return this.changeStatusOfCallerInBulk()
                            break;
                    }
                }}
                disabled={this.state.phoneNumbers && this.state.phoneNumbers.length > 0 ? false:true}
                ref={(c) => this.submitPhoneNumbers = c}>
                Submit
                </FrappButton>
                </div>
                {
                this.state.unprocessedNumbers.length === 0
                ? null
                : this.renderUnprocessedNumbers()
                }
                {
                    this.state.isMobileNumInvalid.show && <span className='failure-msg'>{this.state.isMobileNumInvalid.msg}</span>
                }
                {
                    this.state.isActionTypeNotSelected && <span className='failure-msg'>Please select an action to change the status</span>
                }
                </div>
            </Popup>
        )
    }

    renderLeadsManagement = () => {
        const { leadsManagement: { isOpen, type, callerData }, activeCallers, rowSelected } = this.state
        const selectedCallers = activeCallers && activeCallers.length > 0 ?
            activeCallers.filter(item => item.checked) : []
        return (
            <Popup
                show={isOpen}
                closePopup={() => {
                    this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                    this.addCheckedField()
                    this.setState({ leadsManagement: { isOpen: false, type: '' } })
                    this.setState({ selectAll: false })
                }}
                size='sm'
                heading={`Assign Leads`}
            >
                <LeadsManagement type={type} projectId={this.props.match.params.id} callerData={callerData} selectedCallers={rowSelected}
                    updateAlpCallers={() => {
                        this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                        this.addCheckedField()
                        this.setState({ leadsManagement: { isOpen: false, type: '' } })
                    }} />
            </Popup>
        )
    }

    renderLeadsTransfer = () => {
        const { leadsTransfer: { isOpen, callerData, transfer_type }, activeCallers } = this.state
        const restOfAgents = activeCallers && callerData && activeCallers.filter(({id}) => id !== callerData && callerData.id)
        return (
            <Popup
                show={isOpen}
                closePopup={() => {
                    this.setState({ leadsTransfer: { isOpen: false} })
                    this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                }}
                size='sm'
                heading={`Transfer Leads`}
            >
                {
                    transfer_type == 'by_lead_id' ? <TransferById teleprojectId={this.props.match.params.id} callers={activeCallers}/> 
                    :
                    <TransferAsPerCaller callerData={callerData} restOfAgents={restOfAgents} teleprojectId={this.props.match.params.id} />
                }
            </Popup>
        )
    }
}
export default JobApplicationsActive